@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');




* {
  margin: 0;
  padding: 0;
}

html,
body {
  font-family: 'Righteous', sans-serif;
  ;
  /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
  font-size: 15px;
  font-weight: normal;
}

span {
  font-family: 'Righteous', sans-serif;
}

button {
  font-family: 'Righteous', sans-serif;
}

h1,
h3 {
  font-weight: normal;

}

h1 {
  font-size: 30px;
  /* text-decoration: underline; */
  /* text-align: center; */
}

hr {
  color: black;

}

textarea {
  resize: none;
}

a {
  color: inherit;
}




::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  /* background:rgba(85, 85, 85, 0.753);  */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(255, 196, 87);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: rgb(255, 196, 87); */
  background: rgb(255, 87, 87);
  cursor: pointer;
}

.Logo {
  width: 12rem;
  height: 8rem;
  /* border: solid 2px orange; */
  border-radius: 30%;
  margin: 0.5rem 0rem 0.5rem 0rem;

}

#mastercase #mastercase-content {
  margin-left: 3%;
  margin-right: 3%;
  border-left: solid 2px orange;
  border-right: solid 2px orange;
}

/*----carosello---*/
.carosello {
  position: relative;
  width: 100%;
  height: 90vh;
  box-sizing: border-box;
  padding: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  background: black;
}

.slide {
  position: relative;
  min-width: 100%;
  height: 100%;
  transition: 1s;
  overflow: hidden;
}

#goLeft {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 10%;
  height: 100%;
  background: none;
  border: none;
  outline: none;
}

#goRight {
  position: absolute;
  Right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 10%;
  height: 100%;
  background: none;
  border: none;
  outline: none;
}

#goLeft:hover {
  background: rgba(0, 0, 0, 0.253);
  transition: 1s;
  cursor: pointer;
}

#goRight:hover {
  background: rgba(0, 0, 0, 0.253);
  transition: 1s;
  cursor: pointer;
}

/*-------carosello listino--------*/
#showcase-review h1 {
  padding-top: 5rem;
  background-color: #e7e5e1d0;
  text-align: center;
}

#showcase-caros-sell {
  background-color: #e7e5e1d0;
}

#showcase-caros-sell h1 {
  background-color: #e7e5e1d0;
  text-align: center;
}

.carosello-list {
  position: relative;
  width: 100%;
  height: 60vh;
  box-sizing: border-box;
  padding: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  background-color: #e7e5e1d0;
}

.slide-list {
  position: relative;
  min-width: 20%;
  height: 80%;
  transition: 1s;
  overflow: hidden;
  border-radius: 10px;
  background-color: white;
  margin-left: 10vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.548);
}

.slide-list:hover {
  cursor: pointer;
  transition: 0.4s;
  height: 85%;
  border-radius: 0px;
}

.test-slide {
  height: 100%;
  width: 100%;
  background-color: white;

}

.header-slide {
  /* background-color: rgb(240, 211, 157); */
  height: 70%;
  width: 100%;
}

.info-slide {
  padding-top: 12%;
  background-color: rgb(243, 237, 226);
  height: 30%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.info-footer {
  background-color: rgb(243, 237, 226);
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 4%;
}

.info-footer span {
  margin: 5%;
  text-align: center;
  padding: 2%;
  width: 7rem;
  border-radius: 10px;
  font-family: 'Righteous', sans-serif;
}

.info-footer span a {
  text-decoration: none;
}

.info-slide .text-color-primary {
  font-family: 'Righteous', sans-serif;
  text-transform: uppercase;
  font-size: 2.4rem;
}

.info-slide label {
  font-size: x-large;
  font-family: 'Righteous', sans-serif;
}


.info-slide .visibility-info-cont {
  visibility: hidden;
}



/*-----utility------*/
.btn {
  text-decoration: none;
  list-style: none;
  display: inline-block;
  font-size: 15px;
  background-color: #555;
  color: white;
  padding: 8px;
}

.btn:hover {
  background-color: #333;
  color: orange;
}

.btn-black {
  padding: 10px;
  background-color: #555;
  color: white;
  border-style: none;
  width: 80px
}

.btn-black:hover {
  background-color: #333;
  color: orange;
  cursor: pointer;
}

.btn-orange {
  text-decoration: none;
  list-style: none;
  display: inline-block;
  font-size: 25px;
  color: orange;
  padding: 8px;
}

.btn-orange:hover {
  background-color: orange;
  color: white;
  transition: 1.0s;
  cursor: pointer
}


.checkbox-wrapper-26 * {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

.checkbox-wrapper-26 input[type="checkbox"] {
  display: none;
}

.checkbox-wrapper-26 label {
  --size: 50px;
  --shadow: calc(var(--size) * .07) calc(var(--size) * .1);

  position: relative;
  display: block;
  width: var(--size);
  height: var(--size);
  margin: 0 auto;
  background-color: #f72414;
  border-radius: 50%;
  box-shadow: 0 var(--shadow) #ffbeb8;
  cursor: pointer;
  transition: 0.2s ease transform, 0.2s ease background-color,
    0.2s ease box-shadow;
  overflow: hidden;
  z-index: 1;
}

.checkbox-wrapper-26 label:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  width: calc(var(--size) * .7);
  height: calc(var(--size) * .7);
  margin: 0 auto;
  background-color: #fff;
  transform: translateY(-50%);
  border-radius: 50%;
  box-shadow: inset 0 var(--shadow) #ffbeb8;
  transition: 0.2s ease width, 0.2s ease height;
}

.checkbox-wrapper-26 label:hover:before {
  width: calc(var(--size) * .55);
  height: calc(var(--size) * .55);
  box-shadow: inset 0 var(--shadow) #ff9d96;
}

.checkbox-wrapper-26 label:active {
  transform: scale(0.9);
}

.checkbox-wrapper-26 .tick_mark {
  position: absolute;
  top: -1px;
  right: 0;
  left: calc(var(--size) * -.05);
  width: calc(var(--size) * .6);
  height: calc(var(--size) * .6);
  margin: 0 auto;
  margin-left: calc(var(--size) * .14);
  transform: rotateZ(-40deg);
}

.checkbox-wrapper-26 .tick_mark:before,
.checkbox-wrapper-26 .tick_mark:after {
  content: "";
  position: absolute;
  background-color: #fff;
  border-radius: 2px;
  opacity: 0;
  transition: 0.2s ease transform, 0.2s ease opacity;
}

.checkbox-wrapper-26 .tick_mark:before {
  left: 0;
  bottom: 0;
  width: calc(var(--size) * .1);
  height: calc(var(--size) * .3);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.23);
  transform: translateY(calc(var(--size) * -.68));
}

.checkbox-wrapper-26 .tick_mark:after {
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(var(--size) * .1);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.23);
  transform: translateX(calc(var(--size) * .78));
}

.checkbox-wrapper-26 input[type="checkbox"]:checked+label {
  background-color: #07d410;
  box-shadow: 0 var(--shadow) #92ff97;
}

.checkbox-wrapper-26 input[type="checkbox"]:checked+label:before {
  width: 0;
  height: 0;
}

.checkbox-wrapper-26 input[type="checkbox"]:checked+label .tick_mark:before,
.checkbox-wrapper-26 input[type="checkbox"]:checked+label .tick_mark:after {
  transform: translate(0);
  opacity: 1;
}


.inp-chb {}

.text-color-primary {
  color: orange;
}

.current {
  text-decoration: none;
  list-style: none;
  padding: 20px;
  background-color: #555;
  color: orange;
}

/*-----navbar-admin------*/

#Navbar-admin {
  background: #e7e5e1d0;
  width: 100%;
  margin-top: 6vh;
}


#Navbar-admin .container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 10vh;
}

#Navbar-admin .container ul {
  display: flex;
  padding-right: 1rem;
}

#Navbar-admin .container ul li {
  list-style: none;
}

#Navbar-admin .container ul li a:hover {
  color: orange;
  border-bottom: 3px solid orange;
}

#Navbar-admin .container ul li a:focus {
  color: orange;
  border-bottom: 3px solid orange;
}


#Navbar-admin .container ul li a {

  font-size: 23px;
}


.Link-adm {
  text-decoration: none;
  list-style: none;
  padding: 10px;
  color: black;

}

/*---Navbar---*/


#nav-btn {
  color: white;
  padding: 20px;
  display: none;
}

#nav-btn:hover {
  color: orange;
  cursor: pointer;
  background: #555;
}

.Link {
  text-decoration: none;
  list-style: none;
  padding: 20px;
  color: white;

}

#Navbar {
  background: #333;
  position: absolute !important;
  top: 0%;
  left: 0%;
  z-index: 2 !important;
  width: 100%;
  top: 0;
  border-bottom: solid 3px orange;
  margin-left: 0 !important;
  height: 3rem;
}

#Navbar .container h1 {
  color: white;
  /* padding: 12px; */
}

#Navbar .container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#Navbar .container ul {
  display: flex;
  padding-right: 1rem;
  height: 100%;
}

#Navbar .container ul li {
  list-style: none;
  height: auto;
}

#Navbar .container ul li a {
  height: 50%;
  padding: 15px;
}

#Navbar .container ul li a:hover {
  background: #555;
  color: orange;
}

#check {
  display: none;
}


/*---showcase--*/
#showcase {
  margin-top: 50px
}

#showcase .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90vh;
  background-color: #e7e5e1d0;
  align-items: center;

}

#showcase .container h1 {
  padding: 40px;
}


/*-----Register------*/

#showcase .container #Register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 400px;
}

#showcase .container #Register input {
  height: 40px;
  margin: 5px;
  padding-left: 10px;
  border-style: none;
  font-size: large;
  border-radius: 6px;
}

#showcase .container #Register input:hover {
  border-color: rgb(43, 38, 38);
  border-style: solid;
  border-width: 1px;
}

#showcase .container #Register button {
  height: 40px;
  width: 60px;
  margin: 5px;
  background-color: #555;
  color: white;
  border-radius: 6px;
  border-style: none;
}

#showcase .container #Register button:hover {
  background-color: #333;
  color: orange;

}

#showcase .container .Link {
  color: #555;
}

#showcase .container .Link:hover {
  color: orange;
}

/*----Login-----*/

#showcase .container #Login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 400px;
}

#showcase .container #Login input {
  height: 40px;
  margin: 5px;
  padding-left: 10px;
  border-style: none;
  font-size: large;
  border-radius: 6px;
}

#showcase .container #Login input:hover {
  border-color: rgb(43, 38, 38);
  border-style: solid;
  border-width: 1px;
}

#showcase .container #Login button {
  height: 40px;
  width: 60px;
  margin: 5px;
  background-color: #555;
  color: white;
  border-radius: 6px;
  border-style: none;
}

#showcase .container #Login button:hover {
  background-color: #333;
  color: orange;

}

#showcase .container .Link {
  color: #555;
}

#showcase .container .Link:hover {
  color: orange;
}


/*---Logout-----*/

#showcase .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 80vh;
  background-color: #e7e5e1d0;
  align-items: center;

}


#showcase .container img {
  height: 250px;
  width: 310px;
  border-radius: 50%;
  padding-bottom: 20px;
}

/*------ footer----*/
#Footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #333;
  color: aliceblue;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: solid 2px orange;
}

/*----/Home----*/

#showcaseHome {
  margin-top: 1%;
}

#testimonialto-carousel-all-testimonial-google-space-tag-all-light {
  height: 50%;
  width: 1px;
  min-width: 100%;
  padding-bottom: 3rem;
}

#testimonialto-carousel-all-testimonial-google-space-tag-all-light .py-2 {
  display: none !important;
}

#showcaseHome .container h1 {
  position: absolute;
  top: 50%;
  left: 75%;
  z-index: 1;
  transform: translate(-50%, -50%);
  color: white;
  background-color: rgba(51, 51, 51, 0.349);
  padding: 10px;
  border: solid 2px orange;
}

#showcaseHome .container h1:hover {
  color: white;
  background-color: orange;
  transition: 1.5s;
}

#showcase2 {
  display: flex;
  flex-direction: column;
  background-color: #e7e5e1d0;
}

#showcase2 #title {

  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background:rgba(0, 0, 0, 0.075); */
  background-color: #e7e5e1d0;
}


#showcase2 .container {
  display: flex;
  min-height: 40vh;
  background: rgba(0, 0, 0, 0.075);
  background-color: #e7e5e1d0;
}

#showcase2 .container .box1 {
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 100px;
  background-color: #e7e5e1d0;
}

/*---card----*/

.card {
  position: relative;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  max-width: 100vh;
  min-width: 60vh;
  /* background: rgba(255, 255, 255, 0.055); */
  /* height: 200px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.6s;
  height: 70vh;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.548);
  background: white;
  border-bottom: 4px solid rgba(255, 166, 0, 0.699);

}


.img-container {
  position: relative;
  top: -50px;
  z-index: 1;
  min-width: 20vh;
  min-height: 20vh;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.548);
  border: solid 3px orange;
  /* transition: 1s; */
  /* transform: scale(1.2);
  transform: rotate(360deg);
  cursor: pointer; */
}

.info-container {
  position: relative;
  margin-top: -200px;
  opacity: 0;
  /* visibility: hidden; */
  /* transition: 0.6s; */
  margin-top: -20px;
  opacity: 1;
  width: 45vh;
  height: 100vh !important;
  visibility: visible;
}

.card .img-container h2 {
  /* color: black !important; */
  color: orange !important;
}

.card:hover .img-container h2 {
  color: orange !important;
}

/* .card:hover {
  transition: 0.6s;
  height: 400px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.548);
  background: white;
  border-bottom: 4px solid rgba(255, 166, 0, 0.699);
}

.card:hover .img-container {
  border: solid 3px orange;
  transition: 1s;
  transform: scale(1.2);
  transform: rotate(360deg);
  cursor: pointer;
} */



/* .card:hover .info-container {
  transition: 0.6s;
  margin-top: -20px;
  opacity: 1;
  visibility: visible;
} */

.img-container i {
  color: orange;
}


#showcase2 .container .box1 .card h1 {
  color: orange;
  text-decoration: none;

}

/*fine card*/

#showcase2 .container .box2 {
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 100px;
  flex: 1;
  background-color: #e7e5e1d0;
}

#showcase2 .container .box2 h1 {
  color: orange;
  text-decoration: none;

}


#showcase2 .container .box3 {
  flex: 1;
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 100px;
  background-color: #e7e5e1d0;
}

#showcase2 .container .box3 h1 {
  color: orange;

  text-decoration: none;
}


.parag {
  padding: 15px;
}


/*----admin----*/

#showcaseAdminImp .container {
  display: flex;
  background-color: #e7e5e1d0;
  justify-content: center;
  min-height: 90vh;
}

#showcaseAdminImp .container .inside-container {
  display: flex;
  justify-content: center;
  width: 80%;

}


#showcaseAdminImp .container .inside-container #input-text {
  display: flex;
  flex-direction: column;
  flex: 1;
}

#showcaseAdminImp .container .inside-container #input-file {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}


#showcaseAdminImp .container .inside-container #input-text h1 {
  padding: 40px 30px;
}

#showcaseAdminImp .container .inside-container #input-text h3 {
  padding: 10px 30px;
}


#showcaseAdminImp .container .inside-container #input-text input {
  height: 40px;
  margin: 20px 30px;
  padding-left: 10px;
  border: none;
  border-bottom: 2px solid orange;
  background-color: rgba(51, 51, 51, 0);
  font-size: large;
}

#showcaseAdminImp .container .inside-container #input-text .input-text-col {
  display: flex;
}

#showcaseAdminImp .container .inside-container #input-text textArea {
  height: 200px;
  margin: 0px 30px;
  margin-bottom: 20px;
  padding-left: 10px;
  border-style: none;
  font-size: large;
}


#showcaseAdminImp .container .inside-container #input-text .inp-text:hover {
  border-color: rgb(43, 38, 38);
  border-style: solid;
  border-width: 1px;
}


#showcaseAdminImp .container .inside-container #input-file #custom-uploader {

  margin: 5px;

}


#showcaseAdminImp .container .inside-container #input-file h1 {
  padding: 40px 30px;
}


#showcaseAdminImp .container .inside-container #input-file #image-preview {
  min-height: 200px;
  width: 500px;
  border: 2px solid #555;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px
}

#showcaseAdminImp .container .inside-container #input-file #image-preview .image-preview_img {
  display: none;
  width: 100%;
  height: 300px;
}

#showcaseAdminImp .container .inside-container #input-file #image-preview .carosello {
  display: none;
  width: 100%;
  height: 300px;
  flex-direction: row !important;
}

/*----about----*/

#showcaseAbout {
  margin-top: 3%
}

#showcaseAbout .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #e7e5e1d0;
  align-items: center;

}

#showcaseAbout .container .searchBar input {
  padding-top: 8px;
  padding-left: 5px;
  padding-bottom: 5px;
  border: none;
  font-size: 25px;
  width: 40rem;

}

#showcaseAbout .container .searchBar button {
  margin-left: 10px;
  padding: 7px;
}

#showcaseAbout .container .searchBar button:hover {
  transform: scale(1.3);
  transition: 0.5s;
  cursor: pointer;
  color: orange;
}

#showcaseAbout .container .element {
  display: flex;
  height: 285px;
  width: 70%;
  margin: 10px 0px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.521);
  overflow: hidden;
  background-color: rgba(239, 243, 247, 0.753);
  /* border: solid 3px rgba(255, 166, 0, 0.678); */
  border-radius: 5px;
  margin: 30px 0px;
}

#showcaseAbout .container .element .div-immagine-auto {
  display: flex;
  flex: 0.9;
  flex-direction: column;
  background: rgba(255, 166, 0, 0.678);
  position: relative;
  border-bottom-right-radius: 150px;
  border-top-right-radius: 150px;
  /* border: solid 1.5px rgba(255, 166, 0, 0.678); */

  overflow: hidden;
}



#showcaseAbout .container .element .div-immagine-auto img {
  height: 100%;
  width: 100%;

}

#showcaseAbout .container .element .div-immagine-auto h1 {
  color: aliceblue;
  margin: 10px 20px;
  padding: 0;
  text-decoration: none;
}

#showcaseAbout .container .element .div-info-auto {
  display: flex;
  flex: 1.1;
  margin: 0;
  flex-direction: column;
}


#showcaseAbout .container .element .div-info-auto h1 {
  margin: 10px 20px;
  padding: 0;
  text-decoration: none;
}

#showcaseAbout .container .element .div-info-auto .div-description {
  display: flex;
  padding-left: 20px;

}

#showcaseAbout .container .element .div-info-auto .div-btn {
  display: flex;
  justify-content: center;
}

#showcaseAbout .container .element .div-info-auto .div-btn span {
  margin-top: 10px;
  font-weight: normal
}

#showcaseAbout .container .element .div-info-auto .div-description .div-info-auto-col {
  display: flex;
  flex: 1;
  flex-direction: column;
}

#showcaseAbout .container .element .div-info-auto .div-description .div-info-auto-col div {
  margin: 15px 0px;
}

#showcaseAbout .container .element .div-info-auto .div-description .div-info-auto-col div label {
  margin: 0px 15px;
}

#showcaseAbout .container h1 {
  padding: 40px;
}


#showcaseAbout .container .element:hover {
  transform: scale(1.1);
  transition: 0.3s
}

/*------ noleggio------*/
#showcaseNoleggio {
  /* margin-top: 60px */
  margin-top: 3%;

}

#showcaseNoleggio .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #e7e5e1d0;
  align-items: center;

}

#showcaseNoleggio .container .searchBar input {
  padding-top: 8px;
  padding-left: 5px;
  padding-bottom: 5px;
  border: none;
  font-size: 25px;
  width: 40rem;

}

#showcaseNoleggio .container .searchBar button {
  margin-left: 10px;
  padding: 7px;
}

#showcaseNoleggio .container .searchBar button:hover {
  transform: scale(1.3);
  transition: 0.5s;
  cursor: pointer;
  color: orange;
}


#showcaseNoleggio .container #title-noleggio {
  margin-top: 1%;
  padding: 40px;
}

#showcaseNoleggio .container>h3 {
  margin-top: 1%;
  width: 80%;
}

#showcaseNoleggio .container .carosello {
  margin-top: 4%;
  width: 50vh;
  height: 60vh;
}

#showcaseNoleggio #form-noleggio {
  display: flex;
  flex-direction: column;
}

#showcaseNoleggio #form-noleggio .inp-text {
  height: 40px;
  margin: 20px 30px;
  padding-left: 10px;
  border: none;
  border-bottom: 2px solid orange;
  background-color: rgba(51, 51, 51, 0);
  font-size: large;
}





/*-----info-----*/
#showcaseInfo {
  margin-top: 50px
}

#showcaseInfo .container {
  display: flex;
  background-color: #e7e5e1d0;
  flex-direction: column;
}

#showcaseInfo .container .div-map h1 {
  position: absolute;
  top: 20%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  color: white;
  background-color: rgba(51, 51, 51, 0.349);
  padding: 10px;
}

#showcaseInfo .container .div-map h1:hover {
  color: white;
  background-color: orange;
  transition: 1.5s;
}

#showcaseInfo .container .inside-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

#hr-social {
  width: 1915px !important;
}

#showcaseInfo .container .inside-container .div-email {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
}

#showcaseInfo .container .div-map iframe {
  width: 100%;
  height: 600px;
  border: none;
}

#showcaseInfo .container .inside-container .div-email h1 {
  padding: 20px 0px;
  text-decoration: underline;

}

#showcaseInfo .container .inside-container .div-email input {
  margin: 15px 0px;
  height: 30px;
  width: 60%;
  padding-left: 5px;
  font-size: large;
  border-style: none;
}

#showcaseInfo .container .inside-container .div-email input:hover {
  border-color: rgb(43, 38, 38);
  border-style: solid;
  border-width: 1px;
}

#showcaseInfo .container .inside-container .div-email textarea {
  margin: 15px 0px;
  height: 400px;
  width: 100%;
  padding: 10px 10px;
  font-size: large;
  border-style: none;

}

#showcaseInfo .container .inside-container .div-email textarea:hover {
  border-color: rgb(43, 38, 38);
  border-style: solid;
  border-width: 1px;
}


/*--showcaseAdminmod--*/
#showcaseAdminMod .container {
  display: flex;
  background-color: #e7e5e1d0;
  justify-content: center;
  min-height: 90vh;

}

#showcaseAdminMod .container .inside-container {
  display: flex;
  width: 50%;
  flex-direction: column;
}

.content-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.content-table thead tr {
  background-color: rgba(255, 166, 0, 0.699);
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}

.content-table th,
.content-table td {
  padding: 12px 15px;
}

.content-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.content-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.content-table tbody tr:hover {
  font-weight: bold;
  color: rgba(255, 166, 0, 0.575);
  cursor: pointer;
}

.content-table tbody tr:last-of-type {
  border-bottom: 2px solid rgba(255, 166, 0, 0.699);
}




/*----Dialog-----*/

#Dialog {
  padding: 15px;
  position: absolute;
  top: 12%;
  left: 30%;
  background: whitesmoke;
  border: solid 1px orange;
  display: flex;
  flex-direction: column;
  display: none;
  overflow-y: auto;
  max-height: 60vh;
}

#Dialog #footer-dialog {
  display: flex !important;
  justify-content: flex-end;
  /* align-items: end; */
}


#Dialog input {

  height: 40px;
  margin: 0px 30px;
  padding-left: 10px;
  border: solid 1px rgba(255, 166, 0, 0.411);
  font-size: large;

}

#Dialog button {

  margin: 10px 10px;
  font-size: medium;
}

#Dialog input:hover {
  border-color: rgb(43, 38, 38);
  border-style: solid;
  border-width: 1px;

}

#Dialog textarea:hover {
  border-color: rgb(43, 38, 38);
  border-style: solid;
  border-width: 1px;

}

#Dialog h1 {

  padding: 30px;

}

#Dialog textarea {

  height: 200px;
  margin: 0px 30px;
  margin-bottom: 20px;
  padding-left: 10px;
  border: solid 1px rgba(255, 166, 0, 0.411);
  font-size: large;
  width: 88%;
}

#Dialog h3 {

  padding: 10px 30px;
}

#Dialog .input-text-col {

  display: flex;
  align-content: center;
}

#Dialog .input-text-col div .btn-orange {
  margin: 0px 30px;
  padding: 0PX 3px;
}





#Dialogimg {
  position: fixed;
  z-index: 1000;
  top: 10%;
  left: 10%;
  min-width: 25vh;
  min-height: 30vh;
  background-color: whitesmoke;
  border-radius: 6px;
  border: solid 2px orange;
  overflow-y: auto;
  max-height: 60vh;
}

#Dialogimg #div-bottoni {
  display: flex;
  min-width: 20vh;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  /* background-color: orange; */
}


#Dialogimg #div-bottoni .btn-black {
  height: 35px;
  margin-top: 20px;
  border: none !important;
}

#Dialogimg .div-img {
  min-width: 25vh;
  min-height: 20vh;
  display: flex;
  flex-direction: row !important;
  padding: 2%;

}

#Dialogimg img {
  width: 32vh;
  height: 15vh;
  margin: 20px 20px;
  border-radius: 4px;
}

#Dialogimg #div-bottoni .btn-orange {
  font-size: medium;
  margin-top: 20px;
  border: none;
}

#Dialogimg #div-bottoni .btn-orange:hover {
  border: 2px solid white;
  transition: none;
}


/*----showcaseSell-----*/

#showcaseSell {
  margin-top: 3%
}

#showcaseSell .container {
  display: flex;
  background-color: #e7e5e1d0;
  min-height: 90vh;
  flex-direction: column;
  align-items: center;
}

#showcaseSell .container .inside-container {
  display: flex;
  justify-content: center;
  width: 80%;

}


#showcaseSell .container .inside-container #input-text {
  display: flex;
  flex-direction: column;
  flex: 1;
}

#showcaseSell .container .inside-container #input-file {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}


#showcaseSell .container .inside-container #input-text h1 {
  padding: 40px 30px;
}

#showcaseSell .container .inside-container #input-text h3 {
  padding: 10px 30px;
}


#showcaseSell .container .inside-container #input-text input {
  height: 40px;
  margin: 20px 30px;
  padding-left: 10px;
  border: none;
  border-bottom: 2px solid orange;
  font-size: large;
  background-color: #e9e6df00;
}

#showcaseSell .container .inside-container #input-text .input-text-col {
  display: flex;
}

#showcaseSell .container .inside-container #input-text textArea {
  height: 200px;
  margin: 0px 30px;
  margin-bottom: 20px;
  padding-left: 10px;
  border-style: none;
  font-size: large;
}


#showcaseSell .container .inside-container #input-file #custom-uploader {

  margin: 5px;

}


#showcaseSell .container .inside-container #input-file h1 {
  padding: 40px 30px;
}

#custom-email-uploader {

  margin: 0px 10px;
}

#showcaseSell .container .inside-container #input-file div .inp-text {
  height: 40px;
  width: 40vh;
  margin: 10px 0;
  padding-left: 10px;
  border-style: none;
  font-size: large;
}

#showcaseSell .container .inside-container #input-file div h3 {
  padding-top: 20px;
  padding-right: 20px;
}

#showcaseSell .container .inside-container #input-file div {
  display: flex;
}


#showcaseSell .container .inside-container #input-file #image-email-preview {
  min-height: 200px;
  width: 500px;
  border: 2px solid #555;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px
}

#showcaseSell .container .inside-container #input-file #image-email-preview .carosello {
  display: none;
  width: 100%;
  height: 300px;
  flex-direction: row !important;
}


#showcaseSell .container .inside-container #input-file #image-email-preview .image-preview_img {
  display: none;
  width: 100%;
  height: 300px;
}


/*----section social----*/

#section-social #title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #e7e5e1d0;
}

#section-social #title h1 {
  padding-top: 40px;
}

#section-social .container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40vh;
  background-color: #e7e5e1d0;
}

#section-social .container .img-social {
  margin: 90px 30px;
  color: rgba(0, 0, 0, 0.644);
  border: solid 1px rgba(0, 0, 0, 0.288);
  padding-left: 13px;
  padding-bottom: 13px;
  padding-top: 13px;
  padding-right: 1px;
  border-radius: 100%;
  min-width: 65px;
  background: white;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.548);
}

#section-social .container .img-social:hover {
  margin-top: 40px;
  transition: 0.5s;
  cursor: pointer;
  /* transform: rotate(360deg); */
  transition: 0.5s;
}


#section-social .container #facebook:hover {
  color: white;
  background: blue;
  border-color: blue;
}


#section-social .container #instagram:hover {
  color: white;
  background: rgb(233, 71, 146);
  border-color: rgb(233, 71, 146);
}

#section-social .container #watsapp:hover {
  color: white;
  background: rgb(27, 202, 27);
  border-color: rgb(27, 202, 27);

}



#showcaseInfoPage {
  margin-top: 64px
}

#showcaseInfoPage .container {
  display: flex;
  background-color: #e7e5e1d0;
  min-height: 90vh;
  flex-direction: column;
  align-items: center;
}

#showcaseInfoPage .container .inside-container {
  display: flex;
  justify-content: center;
  width: 55%;
  align-items: center;
  flex-direction: column;
  padding: 5vh 10vh;
  border: SOLID 2PX orange;
  margin-top: 10vh;
  margin-bottom: 10vh;
  background-color: rgba(255, 255, 255, 0.349);
  /* opacity: 0.5; */
  border-radius: 4px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.479);
}

#showcaseInfoPage .container .inside-container .div-carosello {
  display: flex;
  margin-top: 5vh;
}

#contattaci-btn {
  margin: 10px;
  border: solid 2px orange;
  border-radius: 4px;
}

#indietro-btn {
  margin: 10px;
  border: solid 2px orange;
  border-radius: 4px;
}

#div-desc-carosello {
  display: flex;
  flex-direction: column;
}

#txt-area-descrizione {
  padding: 10px;
  width: 45vh;
  min-height: 20vh;
  font-size: 17px;
  border: none;
  border-radius: 10px;
  resize: none;
  background-color: rgba(255, 255, 255, 0);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

#div-desc-descrizione {
  display: flex;
  align-items: center;
  margin-top: 5%;
}

#div-desc-descrizione div {
  flex: 1;
}

#showcaseInfoPage .container .inside-container .div-carosello ul li {
  margin: 20px 0px;
  text-decoration: none;
  list-style-type: none;
  display: flex;

}


#showcaseInfoPage .container .inside-container .div-carosello ul li h3 {
  text-decoration-line: underline;

}


#showcaseInfoPage .container .inside-container .div-carosello ul li i {
  color: orange;
  padding: 0px 20px;
}



#showcaseInfoPage .container .inside-container .div-carosello .carosello {
  width: 100%;
  height: 50vh;
  margin-right: 5vh;
  border: solid 2px orange;
  border-radius: 5px;
}





/*----media query------*/
@media (max-width: 1100px) {

  #mastercase #mastercase-content {
    margin: 0 !important;
    border: none;
  }

  #showcaseHome {
    height: 20%;
    justify-content: center;
    align-items: center;
    margin-top: 20%;

  }

  #showcaseHome .container h1 {
    position: absolute;
    top: 13%;
    left: 65%;
    z-index: 1;
    transform: translate(-50%, -50%);
    color: white;
    background-color: rgba(51, 51, 51, 0.349);
    padding: 10px;
    border: solid 2px orange;
  }


  #showcase2 {
    width: 100%;
  }

  #showcase2 .container {
    margin-top: 45% !important;
    /* margin-left: 3% !important; */
    min-height: 20vh;
    padding: 5%;
    display: flex;
    width: 90% !important;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
  }


  #showcase2 .container .box1 {
    margin: 5%;
    margin-bottom: 25%;
    padding: 0;
    background-color: none;
  }

  #showcase2 .container .box1 .card .img-container {
    min-width: 16vh;
    min-height: 16vh;
    padding: 0;

  }

  #showcase2 .container .box1 .card,
  #showcase2 .container .box2 .card {
    min-width: 50vh !important;
    width: 60vh !important;
    padding: 0 !important;
  }

  #showcase2 .container .box1 .card .info-container {
    max-height: 60vh;
    max-width: 100% !important;

  }

  #showcase2 .container .box2 .card .info-container {
    max-height: 65vh;


  }

  #showcase2 .container .box2 .card .img-container {
    min-width: 16vh;
    min-height: 16vh;
    padding: 0;
  }


  #showcase2 .container .box2 {
    margin: 2%;
    padding: 0;
  }

  #showcase2 .container .box3 {
    margin: 2%;
    padding: 0;
  }



  .card {
    height: 10%;
  }

  #showcaseHome .container .carosello {
    height: auto;
    margin-top: 3vh;
  }


  #showcase2 .container {
    margin-top: 30%;
  }

  .img-container {
    width: 3vh;
    height: 3vh;
    padding: 0px;
    align-items: center;
  }

  .card:hover {
    height: 600px;
  }


  #showcase2 .container {
    width: 70%;
    /* flex-direction: column; */
  }

  #showcase2 #title {
    display: none;
  }

  #showcaseAbout {
    margin-top: 6vh
  }

  #showcaseAbout .container .searchBar {
    display: none;
  }

  #showcaseAbout .container .element {
    /* display: none; */
    width: 90%;
    height: 70%;
  }

  #showcaseAbout .container .element {
    display: flex;
    flex-direction: column;
  }

  #showcaseAbout .container .element .div-info-auto .div-description {
    display: none;
  }

  #showcaseAbout .container .element .div-immagine-auto {
    border-radius: 0 !important;
  }

  #showcaseAbout .container .element .div-info-auto>hr,
  #showcaseAbout .container .element .div-info-auto>h1 {
    display: none;
  }


  #showcaseAbout .container .element .div-info-auto .div-btn span {
    margin-top: 0px;
    font-weight: 20;
    text-align: center;
    text-decoration: none !important;
    margin-bottom: 2%;
    margin-top: 0;
  }

  #showcaseSell {
    margin-top: 6vh
  }

  #showcaseSell .container .inside-container {
    flex-direction: column;
  }

  #showcaseSell .container .inside-container h1 {
    width: 100%;
    margin-left: 0;
    padding: 2vh;
  }

  #showcaseSell .container {
    align-items: baseline;
  }

  #showcaseSell .container .input-text {
    width: 100% !important;
  }

  #showcaseSell .container .inside-container .input-text-col {
    display: flex !important;
    flex-direction: column !important;
  }

  #showcaseSell .container .inside-container #input-text textArea {
    width: 110% !important;
    height: 20vh;
    margin: 5%;
  }

  #showcaseSell .container .inside-container #input-file h1 {
    margin-left: 25%;
  }

  #showcaseSell .container .inside-container #input-file #image-email-preview {
    width: 100%;
    min-height: 20vh;
    margin-left: 10%;
  }

  #showcaseSell .container .inside-container #input-file div .inp-text {
    width: 100%;
    margin-left: 5vh;
  }

  #showcaseSell .container .inside-container #input-file button {
    margin-bottom: 5vh;
  }

  #showcaseAdminImp .container .inside-container {
    flex-direction: column !important;
  }

  #showcaseAdminImp .container .inside-container #input-text .input-text-col {
    flex-direction: column !important;
  }

  #showcaseAdminImp .container .inside-container #input-text .input-text-col .inp-text {
    margin: 1rem;
  }

  #showcaseAdminImp .container .inside-container #input-text .inp-text {
    margin: 1rem;
  }

  #showcaseAdminImp .container .inside-container #input-text textarea {
    width: 100%;
    height: 7rem;
  }

  #showcaseAdminImp #input-file button {
    margin: 1rem;
  }

  #showcaseAdminImp .container .inside-container #input-file #image-preview {
    display: none;
  }


  #Navbar-admin {
    margin-top: 4rem;
  }

  #showcaseAdminMod .container .inside-container table .show-column {
    display: none;
  }

  #showcaseAdminMod .container .inside-container {
    /* text-align: center; */
    width: 100%;
  }

  #showcaseAdminMod .container .inside-container h1 {
    text-align: center;
  }

  #showcaseAdminMod .container .inside-container table {
    min-width: auto;
    width: 100%;
  }

  #Dialog .input-text-col {
    flex-direction: column;
  }
  #Dialog .input-text-col .inp-text{
    margin: 0;
    margin-left: 1rem;
  }
  #Dialog textarea {
    width: 80%;
    height: 20%;
    padding: 3px;
  }

  #Dialog {
    left: 0;
    height: 100%;
    width: 90%;
    max-height: none;
  }

  #Dialog #footer-dialog {
    display: block !important;
  }

  /*---------- infoPage-----------*/

  #showcaseInfoPage {
    /* margin-top: 34px */
    width: 100%;
  }

  #showcaseInfoPage .container {
    width: 100%;
    /* margin: 0px; */
  }

  #showcaseInfoPage .container .inside-container {
    padding: 0;
    display: flex;
    width: 100%;
  }

  #showcaseInfoPage .container .inside-container .div-carosello {
    padding: 0;
    width: 95%;
  }

  #showcaseInfoPage .container .inside-container .div-carosello div {
    width: 100%;
  }

  #showcaseInfoPage .container .inside-container .div-carosello #div-desc-carosello {
    width: 35%;
  }

  #txt-area-descrizione {
    width: auto;
  }

  #showcaseInfoPage .container .inside-container .div-carosello .carosello {
    width: 100% !important;
    height: 25vh;
    /* margin: 0; */

  }

  #showcaseInfoPage .container .inside-container .div-carosello #div-desc-carosello .btn-orange {

    text-decoration: none;
    list-style: none;
    display: inline-block;
    font-size: 25px;
    padding-left: 30%;

  }


  #showcaseInfoPage .container .inside-container .div-carosello {
    /* flex-direction: column; */
  }

  #div-desc-descrizione {
    flex-direction: column;
  }

  #div-desc-descrizione div h1 {
    margin-bottom: 10px;
    text-align: left !important;
  }

  .slide-list {

    min-width: 100%;
    height: 98%;
    margin: 0;
  }


  #Dialogimg {
    width: 60%;
    /* flex-direction: column; */
  }

  #Dialogimg div .div-img {
    /* width: 50%; */
    flex-direction: column !important;
    width: 85%;
  }

  #Dialogimg div .div-img img {
    width: 100%;
    height: 50%;
  }


  /* Nav Button */

  #nav-btn {
    color: white;
    padding: 20px;
    margin-right: 5vh;
    display: block;
  }

  #check:checked~#nav-btn {
    background: #555;
    color: orange;
  }

  #check:checked~#list-navbar {
    left: 0vh;
    transition: 1s;
  }

  #Navbar {
    height: 5rem !important;
  }

  #Navbar .container i {
    margin-left: 0 !important;

  }


  #list-navbar {
    margin-top: 10rem;
    left: -220vh;
    width: 100% !important;
    flex-direction: column;
    display: flex;
    align-items: flex-end;
    background: #555;
    position: absolute;
    z-index: 0;
    height: 60vh;
  }


  #list-navbar li {
    font-size: 40px;
    padding: 20px 0;
    background: #555;
    border: solid 3px #555;
    ;
    width: 100%;
  }

  #Navbar .container {
    flex-direction: column;
    align-items: flex-start;
  }

  #Navbar .container h1 {
    display: none;
  }

  /*-------Noleggio-------*/
  #showcaseNoleggio {
    margin-top: 20%;
  }

  #showcaseNoleggio .container>h2 {
    width: 80%;
  }


  /*----------contatti-----*/
  #showcaseInfo {
    margin-top: 10vh;
  }

  #showcaseInfo .container .div-map {
    display: none;
  }

  #showcaseInfo .container .inside-container {
    justify-content: left;
    /* width: 70vh; */
  }

  #showcaseInfo .container .inside-container .div-email h1 {
    width: 100vh;
    /* padding: 1vh; */
    text-decoration: none;
    margin-bottom: 5%;
  }

  #showcaseInfo .container .inside-container .div-email input,
  #showcaseInfo .container .inside-container .div-email textarea {
    width: 40vh;
  }

  #showcaseInfo .container .inside-container .div-email textarea {
    height: 40vh;
  }

  #section-social .container {
    flex-direction: column;
  }

  #section-social .container .img-social {
    margin: 6vh;
  }

}